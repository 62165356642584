var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { Wizard } from '@shared/ui/misc/Wizard';
import { setEmailForReset } from '@entities/auth';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import SendRecoveryLink from './steps/SendRecoveryLink';
import Confirmation from './steps/Confirmation';
// eslint-disable-next-line react/require-default-props
const ChangePassword = (_a) => {
    var { onClose } = _a, props = __rest(_a, ["onClose"]);
    const { user } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (user) {
            dispatch(setEmailForReset(user.email));
        }
    }, [
        user,
        dispatch,
    ]);
    return (_jsx(Sidebar, Object.assign({}, props, { children: _jsx(SidebarInner, { displayCancelBtn: true, cancelBtnProps: { onClick: onClose }, children: _jsxs(Wizard, { children: [_jsx(SendRecoveryLink, {}), _jsx(Confirmation, {})] }) }) })));
};
export default ChangePassword;
